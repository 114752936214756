import Link from "next/link";
import React from "react";

export default function Global404Page({ error, reset }) {
  return (
    <main className="flex flex-col min-h-dvh justify-center py-6 w-full gap-4 items-center md:gap-4 md:py-12 bg-[#111111]">
      <div className="flex flex-col items-center">
        <div className="text-center space-y-2 md:space-y-3.5 mt-3">
          <h1 className="text-3xl md:text-5xl font-bold tracking-tighter text-slate-100">
            Oops!
          </h1>
          <p className="max-w-[600px] text-slate-200 md:text-lg">
            Maybe the monkeys are at it again.
          </p>
        </div>
        <div className="text-8xl md:text-9xl font-extrabold tracking-tighter flex items-center justify-center pt-4 pb-6">
          <span aria-label="Monkeys" role="img">
            🙈🙉🙊
          </span>
        </div>
      </div>

      <div className="flex w-full max-w-sm flex-col gap-4 px-4 sm:px-0">
        <Link
          href="/"
          className="inline-flex h-10 items-center justify-center rounded-md border border-red-800/10 hover:scale-105 transition-transform duration-300 ease-in-out bg-brand-nebula text-slate-100 shadow-sm text-sm w-full"
        >
          <span className="font-semibold">Go back to the home page</span>
        </Link>
      </div>
    </main>
  );
}
